import React from "react";
import { useDispatch } from "react-redux";

import { updateAppointment } from "./appointments/appointmentSlice";

import { buttonPrimary } from "./css/popupVariables";

const BackButton = ({ appointment }) => {
  const dispatch = useDispatch();

  return (
    <>
      <div
        className="justify-self-start w-full md:w-1/4"
        onClick={() =>
          dispatch(
            updateAppointment({
              updates: {
                stage: appointment.stage - 1,
              },
            })
          )
        }
      >
        <div
          className={`${buttonPrimary} cursor-pointer`}
          style={{
            backgroundColor: "none",
            boxShadow: "none",
            border: "0px",
            fontColor: "black",
          }}
        >
          <div className="justify-self-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5 8.25 12l7.5-7.5"
              />
            </svg>
          </div>
          <div className="text-right w-1/2">Back</div>
        </div>
      </div>
    </>
  );
};

export default BackButton;
