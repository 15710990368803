import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  updateAppointment,
  resetAppointment,
} from "./appointments/appointmentSlice";

import { postBookingConfig, scrollToTop } from "./utils";

import WeeConfetti from "./css/WeeConfetti";

import {
  opaquePopoverParent,
  opaquePopoverTextBox,
  borderColor,
  shadowColor,
  shadow,
  rounded,
} from "./css/popupVariables";

const AppointmentConfirmed = ({ appointment }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    postBookingConfig(appointment);
  }, []);

  useEffect(() => {
    scrollToTop();
  });

  const confirmLeavePage = () => {
    const outlet = appointment?.shopData?.shopContact?.socialLinks[0]?.url;
    dispatch(resetAppointment());
    window.location.replace(outlet);
  };

  return (
    <div
      className={opaquePopoverParent}
      style={{ backgroundColor: "rgba(255, 255, 255, 0.9)" }}
    >
      <div
        className={opaquePopoverTextBox}
        style={{ opacity: 1, borderColor: appointment?.shopData?.color }}
      >
        <div className="pt-5 mb-2 text-2xl font-bold">
          Appointment Confirmed!
        </div>
        <div>We'll See You Soon.</div>
        <div className="mt-2 text-3xl font-bold">🎉</div>
        <div className="relative">
          <div className="absolute inset-0 bg-gradient-to-t from-transparent to-white"></div>
          <img
            src="https://bookings.d14e.io/images/skyline.png"
            alt="Skyline"
            className="w-full h-full object-cover mt-10"
          />
        </div>
        <div className="pb-5">
          <span
            className={`py-1 px-8 w-[100px] cursor-pointer underline border-solid border-[1px] ${borderColor} mx-5 ${rounded} ${shadow} ${shadowColor}`}
            onClick={confirmLeavePage}
            style={{ borderColor: appointment?.shopData?.color }}
          >
            Close
          </span>
        </div>
      </div>
      <WeeConfetti />
    </div>
  );
};

export default AppointmentConfirmed;
