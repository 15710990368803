import { configureStore } from "@reduxjs/toolkit";
import appointmentReducer from "./appointments/appointmentSlice";
import shopReducer from "./appointments/shopSlice";

const store = configureStore({
    reducer: {
        appointments: appointmentReducer,
        shops: shopReducer,
    },
});

export default store;
