import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import AutoCompleteInput from "./AutoCompleteInput";

import { updateAppointment } from "./appointments/appointmentSlice";

const CarPicker = ({ vehicleId = 0, appointment, setClear }) => {
  const [allVehicles, setAllVehicles] = useState([]);
  const [allMakes, setAllMakes] = useState([]);
  const [allModels, setAllModels] = useState([]);
  const [allYears, setAllYears] = useState([]);

  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [year, setYear] = useState("");

  const [newVehicle, setNewVehicle] = useState({
    year: "",
    make: "",
    model: "",
    id: vehicleId,
  });

  const dispatch = useDispatch();

  let makes = [];
  let models = [];
  let years = [];

  // Scrape cars from KBB
  useEffect(() => {
    fetchVehicles("NewCar");
    fetchVehicles("UsedCar");
  }, []);

  // Create an array of all makes
  useEffect(() => {
    if (allVehicles.length > 0) {
      allVehicles.map((vehicle) => {
        !makes.includes(vehicle.make) && makes.push(vehicle.make);
      });
      setAllMakes(makes);
    }
  }, [allVehicles]);

  // Create an array of all models based on the make
  useEffect(() => {
    if (make) {
      allVehicles.map((vehicle) => {
        if (vehicle.make === make) {
          !models.includes(vehicle.model) && models.push(vehicle.model);
        }
      });
      setNewVehicle({ ...newVehicle, make });
      setAllModels(models);
    } else {
      setModel("");
      setYear("");
    }
  }, [make]);

  // Create an array of all years based on the model
  useEffect(() => {
    if (model) {
      allVehicles.map((vehicle) => {
        if (vehicle.make === make && vehicle.model === model) {
          vehicle.years.map((year) => {
            !years.includes(year.year) && years.push(year.year);
          });
        }
      });
      setNewVehicle({ ...newVehicle, model });
      setAllYears(years);
    }
  }, [model]);

  useEffect(() => {
    if (year) {
      setNewVehicle({ ...newVehicle, year });
    }
  }, [year]);

  useEffect(() => {
    if (newVehicle.year && newVehicle.make && newVehicle.model) {
      dispatch(updateAppointment({ updates: { vehicle: newVehicle } }));
      handleSetClear();
    }
  }, [newVehicle]);

  const handleSetClear = () => {
    setClear(true);
  };

  // Fetch vehicles from KBB
  const fetchVehicles = async (vehicleClass) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        operationName: "makemodel",
        variables: {
          sortBy: "make",
          vehicleClass: vehicleClass,
          viewAll: true,
        },
        query:
          "query makemodel($sortBy: String, $vehicleClass: String, $viewAll: Boolean) { makemodel( sortBy: $sortBy vehicleClass: $vehicleClass viewAll: $viewAll ) { vehicles { make model years { year url __typename } __typename } validStartingLetters __typename }}",
      }),
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/bookings/vehicleLookup`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setAllVehicles(result.data.makemodel.vehicles);
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <div className="grid md:grid-cols-3 my-5 gap-2">
        <div className="md:grid-cols-3 mx-6">
          <AutoCompleteInput
            options={allMakes}
            id="Make"
            setValue={setMake}
            appointment={appointment}
          />
        </div>

        <div className="md:grid-cols-3 mx-6">
          <AutoCompleteInput
            options={allModels}
            id="Model"
            setValue={setModel}
            appointment={appointment}
          />
        </div>

        <div className="md:grid-cols-3 mx-6">
          <AutoCompleteInput
            options={allYears}
            id="Year"
            setValue={setYear}
            appointment={appointment}
          />
        </div>
      </div>
    </>
  );
};

export default CarPicker;
