import React from "react";
// import { useDispatch } from "react-redux";
// import { showWidget } from "./appointments/displaySlice";
import ShopAddress from "./ShopAddress";
import { closeButton } from "./css/popupVariables";

const Header = ({ appointment }) => {
  // const dispatch = useDispatch();

  const outlet = () => {
    const isInIframe = window.self !== window.top;

    if (isInIframe) {
      window.parent.postMessage("hideWidget", "*");
    } else {
      console.log("DCBookings doesn't exist redirecting");
      let redirect = appointment?.shopData?.shopContact?.socialLinks[0]?.url;
      window.location.replace(redirect);
    }
  };

  return (
    <div
      className="grid grid-cols-1 pt-4 md:pt-0 md:grid-cols-3 h-auto md:h-[130px]"
      style={{
        borderBottom: "1px solid #000",
      }}
    >
      {/* Close Button Container */}
      <div className="text-start pl-6 flex items-center justify-center md:justify-start relative">
        {/* Close Button */}
        <div
          className={`${closeButton} absolute top-2 left-2`}
          onClick={outlet}
        >
          X
        </div>
      </div>

      {/* Center Content (Logo or Shop Name) */}
      <div className="text-2xl font-semibold flex flex-col items-center justify-center md:items-center md:justify-center">
        {appointment?.shopData?.shopLogo?.logoUrl ? (
          <>
            <div className="flex items-center justify-center md:h-full md:pt-3">
              <img
                src={appointment?.shopData?.shopLogo?.logoUrl}
                alt="company logo"
                style={{ width: "auto", height: "68px" }}
              />
            </div>
          </>
        ) : (
          <div className="flex items-center h-[68px]">
            {appointment?.shopData?.shopName?.name}
          </div>
        )}
        {appointment?.stage === 0 && (
          <div className="flex items-center justify-center h-full">
            Choose a Location
          </div>
        )}
      </div>

      {/* Shop Address or Other Right-Aligned Content */}
      <div className="flex items-center justify-center md:justify-end md:h-full">
        {appointment?.stage > 0 && <ShopAddress appointment={appointment} />}
      </div>
    </div>
  );
};

export default Header;
