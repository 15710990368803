import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { fetchFullSiteData } from "./functions/fetchFullSiteData";
import { updateAppointment } from "./appointments/appointmentSlice";

import Map from "./Map";

import { buttonPrimary } from "./css/popupVariables";

const extractFirstWord = (name) => {
  const match = name.match(/^[^\s]+/i);
  return match ? match[0].toLowerCase() : "";
};

const groupByFirstWord = (data, shop) => {
  const groupedData = data?.reduce((acc, item) => {
    const firstWord = extractFirstWord(item.name);
    if (!acc[firstWord]) {
      acc[firstWord] = [];
    }
    acc[firstWord].push(item);
    return acc;
  }, {});

  let result;
  if (groupedData) {
    result = Object.values(groupedData).map((groupItems) => ({
      groupName: groupItems[0]?.name,
      items: groupItems,
    }));
  }

  // if (shop) {
  //   const shopLowerCase = shop?.toLowerCase();
  //   result = result?.filter((group) =>
  //     group?.groupName?.toLowerCase().includes(shopLowerCase)
  //   );
  // }

  return result;
};

// const ShopLocations = ({ data, shop, fetchFullSiteData, configParams }) => {
const ShopLocations = ({ data, appointment }) => {
  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [isApiLoaded, setIsApiLoaded] = useState(false);
  const mapRef = useRef(null);

  const dispatch = useDispatch();

  let addresses = [];
  let groupedDataArray;
  let theme;

  groupedDataArray = groupByFirstWord(data);
  theme = groupedDataArray[0]?.items[0]?.features?.microsites?.bookings;

  const pushDataToAddresses = () => {
    groupedDataArray?.map((group) =>
      group.items.map((item) => {
        if (item.features?.microsites?.bookings?.enabled) {
          addresses.push({
            item: item,
            address:
              item.name +
              ", " +
              item.business_info?.address1 +
              ", " +
              item.business_info?.city +
              ", " +
              item.business_info?.state +
              ", " +
              item.business_info?.zip,
            shopId: item._id,
            shopName: item.name,
            phoneNumber: item.business_info?.phone_number,
            sameDayBookings:
              item.features?.microsites?.bookings?.sameDayBookings !== undefined
                ? false
                : true,
          });
        }
      })
    );
  };

  useEffect(() => {
    pushDataToAddresses();
  }, [groupedDataArray]);

  useEffect(() => {
    const geocodeAddresses = () => {
      if (!window.google || !window.google.maps) {
        console.error("Google Maps JavaScript API is not loaded properly.");
        return;
      }
      const geocoder = new window.google.maps.Geocoder();
      const newMarkers = [];

      addresses.forEach((address, index) => {
        geocoder.geocode({ address: address.address }, (results, status) => {
          if (status === "OK") {
            const location = results[0].geometry.location;
            newMarkers.push({
              position: { lat: location.lat(), lng: location.lng() },
              title: address,
              id: index,
              shopId: address.shopId,
              sameDayBookings: address.sameDayBookings,
              phoneNumber: address.phoneNumber,
              shopData: address.item,
            });
          } else {
            newMarkers.push({
              position: null,
              title: address,
              id: index,
              shopId: address.shopId,
              sameDayBookings: address.sameDayBookings,
              phoneNumber: address.phoneNumber,
              shopData: address.item,
            });
          }
          if (newMarkers.length === addresses.length) {
            newMarkers.sort((a, b) =>
              a.title?.shopName.localeCompare(b.title?.shopName)
            );
            setMarkers(newMarkers);
          }
        });
      });
    };

    if (isApiLoaded) {
      geocodeAddresses();
    }
  }, [isApiLoaded]);

  const handleApiLoad = () => {
    setIsApiLoaded(true);
  };

  const handleMouseOver = (marker) => {
    if (mapRef.current) {
      mapRef.current.panTo(marker.position);
      mapRef.current.setZoom(9);
    }
    setSelectedMarker(marker);
  };

  const handleMouseOut = (position) => {
    setSelectedMarker(null);
  };

  return (
    <div className="pt-6 mx-5">
      <div className="grid md:grid-cols-3 gap-5 font-roboto">
        <div className="md:h-[500px]">
          <div
            className="md:h-[500px] overflow-y-scroll"
            style={{ marginBottom: "10px" }}
            onMouseOut={() => handleMouseOut(markers[0]?.position)}
          >
            {markers.map((marker, index) => {
              const [businessName, ...addressParts] =
                marker.title.address.split(",");

              return (
                <div
                  className={`${buttonPrimary} cursor-pointer mb-3 md:w-[220px]`}
                  style={{
                    backgroundColor: "white",

                    boxShadow:
                      theme?.shadow === "shadow-md"
                        ? `0 4px 6px ${theme?.color}33`
                        : "none",

                    border: `1px solid ${theme?.color}`,
                    fontColor: "black",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                  onClick={() => {
                    fetchFullSiteData(appointment.domain, marker.shopId).then(
                      (response) => {
                        dispatch(
                          updateAppointment({
                            updates: {
                              stage: appointment.stage + 1,
                              shopData: response,
                            },
                          })
                        );
                      }
                    );
                  }}
                  onMouseOver={() => handleMouseOver(marker)}
                >
                  <div className="grid grid-cols-1 w-full pl-2">
                    <div className="font-semibold text-sm">{businessName}</div>
                    {addressParts.length > 0 && (
                      <div className="text-xs">
                        <div>{addressParts[0]}</div>
                        <div>
                          {addressParts[1]}, {addressParts[2]} {addressParts[3]}
                        </div>
                      </div>
                    )}
                    {marker.sameDayBookings === false && (
                      <div className="text-xs text-red-700">
                        <i>Sameday Bookings Unavailable</i>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="md:col-span-2 md:h-[500px]">
          <Map
            markers={markers}
            handleApiLoad={handleApiLoad}
            mapRef={mapRef}
            selectedMarker={selectedMarker}
          />
        </div>
      </div>
    </div>
  );
};

export default ShopLocations;
