const Datalist = ({ data, id }) => {
  return (
    <datalist id={id}>
      {data.map((item) => (
        <option key={item} value={item} />
      ))}
    </datalist>
  );
};

export default Datalist;
