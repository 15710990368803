import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";

import { updateAppointment } from "./appointments/appointmentSlice";
import { getBookingTimes } from "./functions/getBookingTimes";
import NextBackButtons from "./NextBackButtons";

import {
  dayRow,
  buttonPrimary,
  buttonSecondary,
  cardHeaderClass,
  cardBodyClass,
  textArea,
} from "./css/popupVariables";

const AppointmentPicker = ({ appointment }) => {
  const [stayOrDropOff, setStayOrDropOff] = useState(
    appointment?.slot?.dropOff || null
  );
  const [selectedDate, setSelectedDate] = useState(
    appointment?.slot?.date || null
  );
  const [selectedTime, setSelectedTime] = useState(
    appointment?.slot?.time || null
  );
  const [comments, setComments] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [bounceDate, setBounceDate] = useState(false);
  const [bounceTime, setBounceTime] = useState(false);
  const [disableNext, setDisableNext] = useState(true);
  const [bookingTimes, setBookingTimes] = useState([]);
  const [bookedSlots, setBookedSlots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clear, setClear] = useState(false);

  const dispatch = useDispatch();

  let sameDayBookings =
    appointment?.shopData?.sameDayBookings !== undefined
      ? appointment?.shopData?.sameDayBookings
      : true;
  let defaultSchedule = appointment?.shopData?.shopSchedule || [];

  const fetchSlotTimes = async () => {
    let tmdate = moment(selectedDate).format("YYYY-MM-DD");
    let config = {
      domain: appointment?.domain,
      shopId: appointment?.shopData?.shopId,
    };
    const response = await getBookingTimes(tmdate, appointment);
    setBookingTimes(response.availableSlots);
    setBookedSlots(response.unavailableSlots);
    document
      .getElementById("time-picker")
      .scrollIntoView({ behavior: "smooth", block: "start" });
    setLoading(false);
  };

  useEffect(() => {
    if (selectedDate) {
      setLoading(true);
      fetchSlotTimes(appointment?.shopData?.shopSchedule);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (stayOrDropOff) {
      document
        .getElementById("date-picker")
        .scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [stayOrDropOff]);

  useEffect(() => {
    if (selectedTime) {
      document
        .getElementById("appointment-summary")
        .scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [selectedTime]);

  let hostname = `https://${
    // eslint-disable-next-line no-restricted-globals
    location.hostname.split(".")[0]
  }.api.digitalconcierge.io`;

  if (hostname.includes("localhost")) {
    hostname = "http://localhost:3020";
  }

  const getNext7Days = (fromDate, schedule, sameDayBookings) => {
    const days = [];
    let todayTime = new Date().getTime();
    let fromDateTime = new Date().getTime();
    let i = 0;
    !sameDayBookings && todayTime === fromDateTime && i++;
    while (days.length < 7 && i < 14) {
      // Added a limit to avoid infinite loop in case all days are closed
      const day = new Date(fromDate);
      day.setDate(fromDate.getDate() + i);
      const dayOfWeek = day.getDay();
      const daySchedule = schedule.find((sched) => sched.day === dayOfWeek);

      if (daySchedule && daySchedule.open) {
        days.push(day);
      }
      i++;
    }
    return days;
  };

  const loadNextDates = () => {
    let newStartDate = new Date(startDate);
    let addedDays = 0;
    while (addedDays < 7) {
      newStartDate.setDate(newStartDate.getDate() + 1);
      addedDays++;
    }
    setStartDate(newStartDate);
  };

  const loadPreviousDates = () => {
    let newStartDate = new Date(startDate);
    let subtractedDays = 0;
    let today = new Date();
    today.setHours(0, 0, 0, 0); // Set the time to 00:00:00 to compare only the date part

    while (subtractedDays < 7 && newStartDate > today) {
      newStartDate.setDate(newStartDate.getDate() - 1);
      const dayOfWeek = newStartDate.getDay();
      const daySchedule = appointment?.shopData?.shopSchedule.find(
        (sched) => sched.day === dayOfWeek
      );

      if (daySchedule && daySchedule.open) subtractedDays++;
    }

    // If newStartDate is before today, reset it to today
    if (newStartDate < today) newStartDate = today;

    setStartDate(newStartDate);
  };

  const onCommentChange = (e) => {
    dispatch(updateAppointment({ updates: { comments: e.target.value } }));
  };

  return (
    <>
      <div className="mx-6 pt-6">
        <div
          className={`${cardHeaderClass} flex justify-between`}
          style={{ backgroundColor: appointment?.shopData?.color }}
        >
          Will you be dropping your vehicle off?
          <span className="relative flex h-3 w-3 pt-1.5">
            {stayOrDropOff ? (
              <>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-green-600"></span>
              </>
            ) : (
              <>
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-red-600"></span>
              </>
            )}
          </span>
        </div>
        <div
          className={cardBodyClass}
          style={{ borderColor: appointment?.shopData?.color }}
        >
          <div className="grid grid-cols-2 gap-5 p-2">
            {["wait", "drop"].map((choice) => (
              <div
                key={choice}
                onClick={() => {
                  if (appointment?.dropOffOnly && choice === "drop") {
                    dispatch(
                      updateAppointment({
                        updates: { apptType: choice },
                      })
                    );
                    setSelectedDate(appointment?.slot?.date);
                    setSelectedTime(appointment?.slot?.time);
                    setStayOrDropOff(choice);
                    setBounceDate(true);
                  } else if (!appointment?.dropOffOnly) {
                    dispatch(
                      updateAppointment({
                        updates: { apptType: choice },
                      })
                    );
                    setSelectedDate(appointment?.slot?.date);
                    setSelectedTime(appointment?.slot?.time);
                    setStayOrDropOff(choice);
                    setBounceDate(true);
                  }
                }}
              >
                <center>
                  <div
                    className={`size-20 md:w-[160px] md:h-[80px] p-2 ${
                      choice === stayOrDropOff ? buttonSecondary : buttonPrimary
                    } ${
                      appointment?.dropOffOnly && choice === "wait"
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                    } ${
                      appointment?.dropOffOnly &&
                      choice === "wait" &&
                      "text-gray-300 hover:text-gray-300"
                    }`}
                    style={{
                      backgroundColor:
                        choice === stayOrDropOff
                          ? appointment?.shopData?.color
                          : "white",
                      border: `1px solid ${appointment?.shopData?.color}`,
                      boxShadow:
                        appointment?.shopData?.shadow === "shadow-md"
                          ? `0 4px 6px ${appointment?.shopData?.color}33`
                          : "none",
                    }}
                  >
                    {choice === "wait"
                      ? appointment?.dropOffOnly
                        ? "This is a drop off only service"
                        : "Stay"
                      : "Drop Off"}
                  </div>
                </center>
              </div>
            ))}
          </div>
        </div>

        {stayOrDropOff && (
          <>
            <br />

            <div
              id="date-picker"
              className={`${cardHeaderClass} flex justify-between`}
              style={{
                backgroundColor: appointment?.shopData?.color,
              }}
            >
              Select a Date
              <span className="relative flex h-3 w-3 pt-1.5">
                {selectedDate ? (
                  <>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-green-600"></span>
                  </>
                ) : (
                  <>
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-red-600"></span>
                  </>
                )}
              </span>
            </div>
            <div
              className={cardBodyClass}
              style={{
                borderColor: appointment?.shopData?.color,
              }}
            >
              <div className={dayRow}>
                <div>
                  <div
                    className={`w-9 mr-2 h-[80px] rounded-none cursor-pointer ${buttonPrimary}`}
                    onClick={loadPreviousDates}
                    style={{
                      border: `1px solid ${appointment?.shopData?.color}`,
                      boxShadow:
                        appointment?.shopData?.shadow === "shadow-md"
                          ? `0 4px 6px ${appointment?.shopData?.color}33`
                          : "none",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 19.5 8.25 12l7.5-7.5"
                      />
                    </svg>
                  </div>
                </div>

                {getNext7Days(startDate, defaultSchedule, sameDayBookings).map(
                  (date, index) => (
                    <div
                      key={index}
                      className={`flex justify-center text-center items-center mx-1 mt-2 w-[80px] h-[80px]`}
                    >
                      <div
                        className="w-[80px] cursor-pointer"
                        onClick={() => {
                          dispatch(
                            updateAppointment({
                              updates: {
                                date: moment(date).format("MM/DD/YYYY"),
                              },
                            })
                          );

                          setSelectedDate(date);
                          setBounceDate(false);
                          setBounceTime(true);
                        }}
                      >
                        <div
                          className={
                            date?.setHours(0, 0, 0, 0) ===
                            selectedDate?.setHours(0, 0, 0, 0)
                              ? `flex justify-center h-[80px] md:w-full ${buttonSecondary}`
                              : `flex justify-center h-[80px] md:w-full ${buttonPrimary}`
                          }
                          style={{
                            backgroundColor:
                              date?.setHours(0, 0, 0, 0) ===
                              selectedDate?.setHours(0, 0, 0, 0)
                                ? appointment?.shopData?.color
                                : "white",

                            boxShadow:
                              appointment?.shopData?.shadow === "shadow-md"
                                ? `0 4px 6px ${appointment?.shopData?.color}33`
                                : "none",

                            border: `1px solid ${appointment?.shopData?.color}`,

                            fontColor:
                              date?.setHours(0, 0, 0, 0) ===
                              selectedDate?.setHours(0, 0, 0, 0)
                                ? "black"
                                : "white",
                          }}
                        >
                          {date.toLocaleString("en-US", {
                            weekday: "short",
                          })}
                          <br />
                          {date.getDate()}
                          <br />
                          {date.toLocaleString("en-US", {
                            month: "short",
                          })}
                          <br />
                        </div>
                      </div>
                    </div>
                  )
                )}

                <div>
                  <div
                    className={`w-9 ml-2 h-[80px] rounded-none cursor-pointer ${buttonPrimary}`}
                    onClick={loadNextDates}
                    style={{
                      backgroundColor: "white",

                      boxShadow:
                        appointment?.shopData?.shadow === "shadow-md"
                          ? `0 4px 6px ${appointment?.shopData?.color}33`
                          : "none",

                      border: `1px solid ${appointment?.shopData?.color}`,

                      fontColor: "black",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m8.25 4.5 7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {selectedDate && (
          <>
            <br />
            <div
              id="time-picker"
              className={`${cardHeaderClass} flex justify-between`}
              style={{
                backgroundColor: appointment?.shopData?.color,
              }}
            >
              Select a Time Slot
              <span className="relative flex h-3 w-3 pt-1.5">
                {selectedTime ? (
                  <>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-green-600"></span>
                  </>
                ) : (
                  <>
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-red-600"></span>
                  </>
                )}
              </span>
            </div>
            <div
              className={cardBodyClass}
              style={{
                borderColor: appointment?.shopData?.color,
              }}
            >
              <div className="grid grid-cols-3 gap-5 md:grid-cols-6">
                {loading ? (
                  <div className="col-span-3 md:col-span-6">
                    <center>
                      <img
                        src="https://bdm-files.s3.amazonaws.com/images/spinners/Gear@1x-0.3s-200px-200px.gif"
                        alt="loading"
                      />
                      <br />
                      <br />
                      Finding the best times for you!
                    </center>
                  </div>
                ) : Object.keys(bookingTimes).length === 0 ? (
                  <div className="col-span-3 md:col-span-6">
                    <center>
                      Unfortunately we're all booked. Please select another day.
                    </center>
                  </div>
                ) : (
                  bookingTimes.map((time) => (
                    <div
                      key={time}
                      className={`${
                        time === selectedTime ? buttonSecondary : buttonPrimary
                      } cursor-pointer`}
                      onClick={() => {
                        dispatch(
                          updateAppointment({
                            updates: { time: time },
                          })
                        );
                        setBounceTime(false);
                        setSelectedTime(time);
                        setClear(true);
                      }}
                      style={{
                        backgroundColor:
                          time === selectedTime
                            ? appointment?.shopData?.color
                            : "white",
                        border: `1px solid ${appointment?.shopData?.color}`,
                        boxShadow:
                          appointment?.shopData?.shadow === "shadow-md"
                            ? `0 4px 6px ${appointment?.shopData?.color}33`
                            : "none",
                      }}
                    >
                      {time}
                    </div>
                  ))
                )}
              </div>
            </div>
          </>
        )}

        {selectedDate && selectedTime && (
          <div>
            <br />
            <div
              id="appointment-summary"
              className={cardHeaderClass}
              style={{
                backgroundColor: appointment?.shopData?.color,
              }}
            >
              Appointment Summary
            </div>
            <div
              className={cardBodyClass}
              style={{
                borderColor: appointment?.shopData?.color,
              }}
            >
              <div className="pb-4">
                You've chosen to{" "}
                {stayOrDropOff.toLowerCase() === "wait"
                  ? "stay with"
                  : "drop off"}{" "}
                your{" "}
                <strong>
                  {appointment?.vehicle?.year} {appointment?.vehicle?.make}{" "}
                  {appointment?.vehicle?.model}
                </strong>{" "}
                for service related to <strong>{appointment?.service}</strong>.
                {appointment?.value === "9" &&
                  " Please describe what we need to inspect in the addition comments box below."}
              </div>
              <div className="pb-4">
                The date and time of your service will be{" "}
                <strong>
                  {moment(selectedDate).format("MM/DD/YYYY")} at {selectedTime}
                </strong>
              </div>
              <div>
                <strong>Additional Comments</strong>
                <div>
                  <textarea
                    className={textArea}
                    rows="4"
                    placeholder="Enter any additional comments here..."
                    onChange={onCommentChange}
                    style={{
                      color: "black",
                      backgroundColor: "white",
                      borderColor: appointment?.shopData?.color,
                      outlineColor: appointment?.shopData?.color,
                    }}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <br />
      <div>
        <NextBackButtons appointment={appointment} clear={clear} />
      </div>
    </>
  );
};

export default AppointmentPicker;
