import React from "react";
import { Helmet } from "react-helmet";

const GoogleAnalyticsPixelInjector = ({ gTag }) => {
  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${gTag}`}
      ></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${gTag}');
        `}
      </script>
    </Helmet>
  );
};

export default GoogleAnalyticsPixelInjector;
