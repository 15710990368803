import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import NextBackButtons from "./NextBackButtons";
import CustomerFormFields from "./CustomerFormFields";
import CarPicker from "./CarPicker";

import { updateAppointment } from "./appointments/appointmentSlice";
import { customerLookup, validateNumber } from "./utils";

import {
  inputField,
  emptyInputField,
  cardHeaderClass,
  cardBodyClass,
} from "./css/popupVariables";

const VehicleSelection = ({ appointment }) => {
  const [loading, setLoading] = useState(true);
  const [clear, setClear] = useState(false);
  const [errors, setErrors] = useState({});
  const [vehicles, setVehicles] = useState([]);
  const [inputClicked, setInputClicked] = useState({});
  const [valueCheck, setValueCheck] = useState(["cheater"]);
  const [customer, setCustomer] = useState({});

  const dispatch = useDispatch();

  const formFields = [
    {
      type: "text",
      name: "fname",
      required: true,
      placeholder: "First Name",
      defaultClass: "inputField",
      emptyClass: "emptyInputField",
    },
    {
      type: "text",
      name: "lname",
      required: true,
      placeholder: "Last Name",
      defaultClass: "inputField",
      emptyClass: "emptyInputField",
    },
    {
      type: "text",
      name: "email",
      required: true,
      placeholder: "Email Address",
      defaultClass: "inputField",
      emptyClass: "emptyInputField",
    },
  ];

  useEffect(() => {
    let config = {
      domain: appointment?.domain,
      shopId: appointment?.shopData?.shopId,
    };
    customerLookup(appointment?.customer?.phone, config).then((data) => {
      if (data) {
        setVehicles(data.vehicles);
        dispatch(
          updateAppointment({
            updates: {
              customerId: data.integrationId,
              dcId: data._id,
              customer: {
                ...appointment.customer,
                fname: data.fname,
                lname: data.lname,
                email: data.email,
              },
            },
          })
        );
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    enableNext(valueCheck);
  }, [valueCheck]);

  useEffect(() => {
    if (appointment.vehicle) {
      setClear(true);
    }
  }, [appointment.vehicle]);

  const enableNext = (values) => {
    if (values.length === 0) {
      setClear(true);
    }
  };

  const handleFocus = (fieldName) => {
    setInputClicked({ ...inputClicked, [fieldName]: true });
  };

  const handleBlur = (e, isRequired) => {
    let updatedValues = [];

    if (e.target.value !== "") {
      updatedValues = valueCheck?.filter((item) => item !== e.target.name);
      setValueCheck(updatedValues);
    } else if (!valueCheck?.includes(e.target.name) && isRequired) {
      setValueCheck((oldValues) => [...oldValues, e.target.name]);
    }
  };

  const updateCustomerInfo = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let phoneNumber = "";

    if (fieldName === "phone" && fieldValue.length >= 10) {
      phoneNumber = validateNumber(fieldValue);
      if (!phoneNumber) {
        setErrors({ ...errors, phone: "Invalid phone number" });
      } else {
        delete errors.phone;
        dispatch(
          updateAppointment({
            updates: {
              customer: {
                ...appointment?.customer,
                [fieldName]: fieldValue,
              },
            },
          })
        );
        setClear(true);
      }
    } else {
      switch (fieldName) {
        case "fname":
          if (fieldValue === "") {
            setErrors({
              ...errors,
              fname: "First Name is required",
            });
          } else {
            delete errors.fname;
            dispatch(
              updateAppointment({
                updates: {
                  customer: {
                    ...appointment?.customer,
                    [fieldName]: fieldValue,
                  },
                },
              })
            );
          }
          break;

        case "lname":
          if (fieldValue === "") {
            setErrors({
              ...errors,
              lname: "Last Name is required",
            });
          } else {
            delete errors.lname;
            dispatch(
              updateAppointment({
                updates: {
                  customer: {
                    ...appointment?.customer,
                    [fieldName]: fieldValue,
                  },
                },
              })
            );
          }
          break;

        case "email":
          if (fieldValue === "") {
            setErrors({
              ...errors,
              email: "Email Address is required",
            });
          } else {
            delete errors.email;
            dispatch(
              updateAppointment({
                updates: {
                  customer: {
                    ...appointment?.customer,
                    [fieldName]: fieldValue,
                  },
                },
              })
            );
          }
          break;

        default:
          break;
      }
    }

    setCustomer((prevCustomer) => ({
      ...prevCustomer,
      [fieldName]: fieldValue,
    }));
  };

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center py-12">
          <center>
            <img
              src="https://bdm-files.s3.amazonaws.com/images/spinners/Gear@1x-0.3s-200px-200px.gif"
              alt="loading"
            />
            <br />
            <br />
            Standby while we fetch your information...
          </center>
        </div>
      ) : appointment?.customerId ? (
        <div>
          <div className="text-2xl font-semibold pb-2 mx-6 pt-6">
            Welcome back!
          </div>
          <div className="grid gap-2 md:grid-cols-2 mx-6">
            {vehicles?.map((vehicle, index) => (
              <div
                key={index}
                onClick={() => {
                  dispatch(
                    updateAppointment({
                      updates: { vehicle: vehicle },
                    })
                  );
                  setClear(true);
                  document
                    .getElementById("buttons")
                    .scrollIntoView({ behavior: "smooth", block: "start" });
                }}
              >
                <div className="w-full">
                  <div
                    className={`${cardHeaderClass} flex justify-between`}
                    style={{
                      backgroundColor:
                        appointment?.vehicle?.crossRef[0]?.crossRef ===
                        vehicle?.crossRef[0]?.crossRef
                          ? "green"
                          : appointment?.shopData?.color,
                    }}
                  >
                    {vehicle.year} {vehicle.make} {vehicle.model}
                    {appointment?.vehicle?.id === vehicle.id && (
                      <>
                        <span className="relative flex h-3 w-3 pt-1.5">
                          <span className="relative inline-flex rounded-full h-3 w-3"></span>
                        </span>
                      </>
                    )}
                  </div>
                  <div
                    className={`${cardBodyClass} cursor-pointer`}
                    style={{
                      borderColor:
                        appointment?.vehicle?.crossRef[0]?.crossRef ===
                        vehicle?.crossRef[0]?.crossRef
                          ? "green"
                          : appointment?.shopData?.color,
                    }}
                  >
                    <center>
                      <img
                        src="https://bookings.d14e.io/images/service/car-silhouette.png"
                        alt="generic car"
                        className="opacity-50 max-w-full h-auto"
                      />
                    </center>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="w-full">
            <CarPicker
              vehicleId={vehicles?.length}
              appointment={appointment}
              setClear={setClear}
            />
          </div>
        </div>
      ) : (
        <div className="pt-6">
          <div className="mb-3 text-3xl font-bold">
            <center>Looks like you're a new customer! 👋</center>
          </div>

          <div className="grid w-full">
            {Object.keys(errors).length > 0 && (
              <div className="mb-5 p-1 bg-red-200 border-solid border-[1px] border-red-500 rounded-md text-red-500">
                <ul>
                  {Object.keys(errors).map((error, index) => (
                    <li key={index}>{errors[error]}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="grid md:grid-cols-3 my-5 gap-2">
            <CustomerFormFields
              props={{
                formFields: formFields,
                appointment: appointment,
                customer: customer,
                inputClicked: inputClicked,
                valueCheck: valueCheck,
                emptyInputField: emptyInputField,
                inputField: inputField,
                updateCustomerInfo: updateCustomerInfo,
                handleFocus: handleFocus,
                handleBlur: handleBlur,
              }}
            />
          </div>

          <div className="text-xl mt-5 mx-6">
            Please enter your vehicle information below. If you don't see your
            make, model, or year simply type it in.
          </div>

          <div id="buttons" className="w-full">
            <CarPicker appointment={appointment} setClear={setClear} />
          </div>
        </div>
      )}

      <br />
      <NextBackButtons appointment={appointment} clear={clear} confirm="true" />
    </>
  );
};

export default VehicleSelection;
