import React from "react";
import { useSelector } from "react-redux";
import { selectShops, selectAppointments } from "./selectors";

import "./css/index.css";

import AppointmentConfirmed from "./AppointmentConfirmed";
import AppointmentPicker from "./AppointmentPicker";
import CustomerInput from "./CustomerInput";
import Header from "./Header";
import VehicleSelection from "./VehicleSelection";
import ServiceOptions from "./ServiceOptions";
import ShopLocations from "./ShopLocations";
// import StayOrGo from "./StayOrGo";

import { popupContainer } from "./css/popupVariables";

function PopupWidget() {
  const shops = useSelector(selectShops);
  const appointment = useSelector(selectAppointments);

  return (
    <>
      {/* Full-page overlay */}
      <div
        className="fixed inset-0 bg-black bg-opacity-70"
        style={{
          zIndex: "999 !important",
          background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.8) 16%, rgba(212, 213, 212, 0.8) 74%, rgba(194, 194, 194, 0.8) 100%)",
        }}
      ></div>

      {/* Popup container */}
      <div
        className="fixed inset-0 flex items-center justify-center w-full"
        style={{ zIndex: 1000 }}
      >
        <div
          className={`${popupContainer} shadow-lg rounded-lg overflow-auto max-h-[calc(100vh-100px)] md:w-[800px]`}
        >
          {/* {isOpen && showConfirmation && (
                <StayOrGo
                  setShowConfirmation={setShowConfirmation}
                  confirmLeavePage={confirmLeavePage}
                  config={config}
                  onStartOver={onStartOver}
                />
              )} */}

          <div>
            <Header appointment={appointment} />
            <div
              style={{
                background:
                  "linear-gradient(0deg, rgba(255,255,255,1) 16%, rgba(212,213,212,1) 74%, rgba(194,194,194,1) 100%)",
              }}
            >
              {appointment.stage === 0 && (
                <ShopLocations data={shops} appointment={appointment} />
              )}

              {appointment.stage === 1 && (
                <ServiceOptions appointment={appointment} />
              )}

              {appointment.stage === 2 && (
                <AppointmentPicker appointment={appointment} />
              )}

              {appointment.stage === 3 && (
                <CustomerInput appointment={appointment} />
              )}

              {appointment.stage === 4 && (
                <VehicleSelection appointment={appointment} />
              )}

              {appointment.stage === 5 && (
                <AppointmentConfirmed appointment={appointment} />
              )}
            </div>
            <div className="mt-6 ml-6 col-span-2 flex !text-sm !font-roboto !font-normal">
              <span className="mr-1">Powered by Digital Concierge</span>
              <span>
                <img
                  src="https://bookings.d14e.io/images/powered-by-logo.png"
                  alt="powered by digital concierge"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PopupWidget;
