const theme = {
  border: {
    color: "border-blue-800",
    emptyColor: "border-red-400",
    rounded: "rounded-md",
  },

  background: {
    primary: "bg-white",
    secondary: "bg-blue-800",
    hover: {
      primary: "hover:bg-white",
      secondary: "hover:bg-blue-800/40",
    },
  },

  font: {
    light: "text-white",
    dark: "text-black",
    primary: "text-black",
    secondary: "text-blue-800",
    hover: "hover:text-black",
  },

  shadow: {
    size: "shadow-md",
    color: "shadow-[#000]/20",
    hover: "hover:shadow-blue-800/35",
  },
};

export const primaryBackground = theme.background.primary;
export const secondaryBackground = theme.background.secondary;

export const secondaryHover = theme.shadow.hover;

export const borderColor = theme.border.color;
export const emptyBorderColor = theme.border.emptyColor;
export const rounded = theme.border.rounded;

export const hoverFont = theme.font.hover;
export const darkFont = theme.font.dark;
export const lightFont = theme.font.light;

export const shadow = theme.shadow.size;
export const shadowColor = theme.shadow.color;

export const primaryTextColor = theme.font.primary;
export const secondaryTextColor = theme.font.secondary;

// Container
// export const popupContainer = `p-2 md:px-16 bg-white border-solid border-[1px] md:mx-auto md:w-[800px] font-sans font-roboto ${darkFont} ${shadow} ${shadowColor} ${borderColor} ${rounded}`;
export const popupContainer = `bg-white mx-6 w-[650px] md:mx-auto md:w-[800px] font-sans font-roboto ${darkFont} ${shadow} ${shadowColor} ${borderColor} ${rounded}`;

// Card Header
export const cardHeaderClass = `p-2 text-start font-semibold rounded-t-md font-sans font-roboto ${secondaryBackground} ${lightFont}`;

// Card Body
export const cardBodyClass = `p-4 border-solid border-[1px] rounded-b-md font-sans font-roboto ${borderColor} ${primaryBackground} ${darkFont}`;

// Buttons
export const buttonPrimary = `flex justify-center items-center py-2 border-solid border-[1px] font-sans font-roboto ${hoverFont} ${secondaryHover} ${primaryBackground} ${darkFont} ${shadow} ${shadowColor} ${borderColor} ${rounded}`;
export const buttonSecondary = `flex justify-center items-center py-2 border-solid border-[1px] font-sans font-roboto ${secondaryBackground} ${lightFont} ${shadow} ${shadowColor} ${borderColor} ${rounded}`;

// Close Popover X Button
export const closeButton = `border-solid border-[1px] border-gray-300 text-black w-min !px-2 !py-1 text-gray-400 !font-sans !font-roboto cursor-pointer ${rounded}`;
export const closeMessageTextContainer =
  "mx-auto text-center bg-white font-sans font-roboto";
export const confirmedMessageTextContainer =
  "mx-auto text-center font-sans font-roboto";

// Day Row
export const dayRow =
  "flex overflow-x-auto p-2 md:justify-center font-sans font-roboto ";

// Form Fields
export const inputField = `border-solid box-border border-[1px] p-2 py-3 w-full font-sans font-roboto text-base ${rounded}`;
export const emptyInputField = `border-solid box-border p-2 border-[1px] p-2 py-3 w-full font-sans font-roboto text-base ${shadow} ${shadowColor} ${emptyBorderColor} ${rounded}`;
export const textArea = `border-solid box-border border-[1px] p-2 w-full font-sans font-roboto text-base ${shadow} ${shadowColor} ${borderColor} ${rounded}`;

// Opaque Popover
// export const opaquePopoverParent = `grid flex justify-center items-center items-start md:pt-0 mx-auto text-center place-self-start absolute top-0 left-0 h-full w-full`;
export const opaquePopoverParent = `grid flex justify-center items-center md:pt-0 mx-auto text-center place-self-start absolute top-0 left-0 h-full w-full font-sans font-roboto ${rounded}`;

// export const opaquePopoverTextBox = `flex flex-col justify-center items-center w-[330px] md:w-[400px] bg-white rounded-md b-2 border-solid border-[1px] ${borderColor} ${shadowColor} ${shadow}`;
export const opaquePopoverTextBox = `flex flex-col mt-5 w-[330px] md:w-[400px] bg-white b-2 border-solid border-[1px] font-sans font-roboto ${rounded} ${borderColor} ${shadowColor} ${shadow}`;
