import { createSlice } from "@reduxjs/toolkit";

const shopSlice = createSlice({
  name: "shops",
  initialState: [],
  reducers: {
    addShop(state, action) {
      state.push(action.payload);
    },
    setShops(state, action) {
      return action.payload;
    },
    updateShop(state, action) {
      // logic to update appointment details if necessary
    },
  },
});

export const { setShops, addShop, updateShop } = shopSlice.actions;
export default shopSlice.reducer;
