import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAppointments } from "./selectors";

import PopupWidget from "./PopupWidget";
import GoogleAnalyticsPixelInjector from "./GoogleAnalyticsPixelInjector";

import { fetchDomainOnlyData } from "./functions/fetchDomainOnlyData";
import { fetchFullSiteData } from "./functions/fetchFullSiteData";
import { parseQueryString } from "./utils";

import { setShops } from "./appointments/shopSlice";
import { addAppointment } from "./appointments/appointmentSlice";

function App({ props }) {
  let configParams = props?.configParams;
  let domain = configParams?.domain ?? "";
  let shopId = configParams?.shopId ?? "";

  const queryString = parseQueryString(window.location);

  if (queryString?.domain) {
    domain = queryString?.domain;
  }
  if (queryString?.shopId) {
    shopId = queryString?.shopId;
  }
  const rwg_token = queryString?.rwg_token;

  const dispatch = useDispatch();

  // Setup message listener for postMessage API
  useEffect(() => {
    const handleMessage = (event) => {
      const { siteID } = event.data;
      if (siteID) {
        console.log("Received siteID:", siteID);

        // Use the siteID to load specific content or perform actions
        // Example: Fetch data based on the siteID
        fetchFullSiteData(domain, siteID).then((data) => {
          const appointmentData = {
            stage: 1,
            domain: domain,
            shopData: data,
            rwg_token: rwg_token,
          };

          dispatch(addAppointment(appointmentData));
        });
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [dispatch, domain, rwg_token]);

  useEffect(() => {
    if (domain && shopId) {
      fetchFullSiteData(domain, shopId).then((data) => {
        const appointmentData = {
          stage: 1,
          domain: domain,
          shopData: data,
          rwg_token: rwg_token,
        };

        dispatch(addAppointment(appointmentData));
      });
    } else if (domain) {
      fetchDomainOnlyData(domain).then((data) => {
        dispatch(setShops(data));

        const appointmentData = {
          stage: 0,
          domain: domain,
          rwg_token: rwg_token,
        };

        dispatch(addAppointment(appointmentData));
      });
    } else {
      console.log("Error fetching site config");
    }
  }, [configParams, dispatch, domain, shopId, rwg_token]);

  let appointment = useSelector(selectAppointments);

  return (
    <div
      id="widget-container"
      style={{ width: "100vw", minHeight: "1px", display: "hidden" }}
    >
      <GoogleAnalyticsPixelInjector
        gTag={appointment?.shopData?.pixels?.googleAnalytics?.gTag}
      />
      <PopupWidget />
    </div>
  );
}

export default App;
