// src/widgetEntry.js
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import store from "./store";

import { Provider } from "react-redux";
// import { parseQueryString } from "./utils";

function initializeWidget() {
  const widgetRoot = document.createElement("div");
  widgetRoot.id = "popup-widget-root";
  widgetRoot.style.position = "relative";
  widgetRoot.style.zIndex = 9999;
  document.body.appendChild(widgetRoot);

  // let queryString = parseQueryString(window.location);

  // let domain = "";
  // let shopId = "";
  // if (window.globalEmbedded) {
  //   domain = window.globalDomain;
  //   shopId = window.globalShopId;
  // } else {
  //   domain = queryString.domain !== undefined ? queryString.domain : "";
  //   shopId = queryString.shopId !== undefined ? queryString.shopId : "";
  // }

  // if (!domain && !shopId) {
  //   console.log("Error fetching site config, domain and shopId are empty");
  // }

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    widgetRoot
  );
}

if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", initializeWidget);
} else {
  initializeWidget();
}
