import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateAppointment } from "./appointments/appointmentSlice";

import NextBackButtons from "./NextBackButtons";
import { parseQueryString } from "./utils";
import { buttonPrimary, buttonSecondary } from "./css/popupVariables";

const ServiceOptions = ({ appointment }) => {
  const [clear, setClear] = useState(false);
  const queryString = parseQueryString(window.location);

  const dispatch = useDispatch();

  let serviceOptions = [];
  // let imgPath = "/images/service/icons/";
  let imgPath = "https://bdm-files.s3.amazonaws.com/images/service-icons/";
  if (appointment?.shopData?.services) {
    serviceOptions = appointment?.shopData?.services; // Custom Options
  } else {
    serviceOptions = appointment?.shopData?.serviceOptions; // Default Options
  }

  useEffect(() => {
    if (queryString?.service) {
      const service = serviceOptions.find(
        (service) => service.name === queryString.service
      );

      dispatch(
        updateAppointment({
          updates: {
            service: service.name,
            hours: service.hours,
            dropOffOnly: service.dropOffOnly,
          },
        })
      );
      setClear(true);
    }
  }, [queryString?.service]);

  return (
    <div>
      <div className="grid md:grid-cols-2 justify-items-center mb-5 gap-2 mx-6 pt-6">
        {serviceOptions?.map((service, index) => (
          <div
            className={`w-full cursor-pointer ${
              service.name === appointment?.service
                ? buttonSecondary
                : buttonPrimary
            }`}
            onClick={() => {
              dispatch(
                updateAppointment({
                  updates: {
                    service: service.name,
                    serviceHours: service.hours,
                    dropOffOnly: service.dropOffOnly,
                  },
                })
              );
              setClear(true);
            }}
            key={service.hours}
            style={{
              backgroundColor:
                service.name === appointment?.service
                  ? appointment?.shopData?.color
                  : "white",

              boxShadow:
                appointment?.shopData?.features?.microsites?.bookings
                  ?.shadow === "shadow-md"
                  ? `0 4px 6px ${appointment?.shopData?.color}33`
                  : "none",

              border: `1px solid ${appointment?.shopData?.color}`,

              fontColor:
                service.name === appointment?.service ? "black" : "white",
            }}
          >
            <div className="flex items-center pl-2">
              <img
                src={`${imgPath}${service.icon}`}
                className="w-9 h-9"
                style={{
                  paddingRight: "10px",
                  filter:
                    service.name === appointment?.service
                      ? "invert(100%)"
                      : null,
                }}
                alt={service.name}
              />
            </div>
            <div className="grid w-full gap-2">
              {service.name}
              <br />
              <span style={{ fontSize: "9pt" }}>{service.description}</span>
            </div>
          </div>
        ))}
      </div>
      <NextBackButtons appointment={appointment} clear={clear} />
    </div>
  );
};

export default ServiceOptions;
