import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateAppointment } from "./appointments/appointmentSlice";
import { selectAppointments } from "./selectors";

import { postBookingConfig } from "./utils";

import { buttonPrimary } from "./css/popupVariables";

const NextButton = ({ appointment, clear, confirm = "" }) => {
  const dispatch = useDispatch();

  return (
    <div
      className="justify-self-end w-full md:w-1/2"
      onClick={() => {
        clear &&
          dispatch(
            updateAppointment({ updates: { stage: appointment.stage + 1 } })
          );
      }}
    >
      <div
        className={`${buttonPrimary} ${
          clear
            ? "cursor-pointer"
            : "cursor-not-allowed text-gray-300 hover:text-gray-300"
        }`}
        style={{
          backgroundColor: "white",
          boxShadow:
            appointment?.shopData?.shadow === "shadow-md"
              ? `0 4px 6px ${appointment?.shopData?.color}33`
              : "none",
          border: `1px solid ${appointment?.shopData?.color}`,
          fontColor: "black",
        }}
      >
        {confirm ? (
          <>
            <div className="justify-self-end w-1/2">Confirm</div>
            <div className="font-bold text-left">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 stroke-amber-500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                />
              </svg>
            </div>
          </>
        ) : (
          <>
            <div className="justify-self-end w-1/4 md:w-1/2">Next</div>
            <div className="font-bold text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m8.25 4.5 7.5 7.5-7.5 7.5"
                />
              </svg>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NextButton;
