import React, { useEffect, useState } from "react";

const CustomerFormFields = ({ props }) => {
  const {
    formFields,
    appointment,
    customer,
    updateCustomerInfo,
    inputField,
    emptyInputField,
    inputClicked,
    handleFocus,
    handleBlur,
    valueCheck,
  } = props;

  const [cols, setCols] = useState("");
  const [oneColWidth, setOneColWidth] = useState(null);
  const [oneColField, setOneColField] = useState(null);

  useEffect(() => {
    if (formFields.length > 1) {
      setCols("place-content-center md:grid-cols-3 mx-6");
    } else {
      setOneColWidth("grid justify-center");
      setOneColField("grid bg-white w-full");
    }
  }, []);

  return (
    <>
      {formFields.map((field, index) => (
        <div key={index} className={`${oneColWidth} ${cols}`}>
          <div style={{ width: "100%" }}>
            <input
              type={field.type}
              placeholder={field.placeholder}
              name={field.name}
              id={field.name}
              value={customer[field?.name]}
              required={field.required}
              className={`${oneColField} ${
                inputClicked[field.name]
                  ? valueCheck?.includes(field.name)
                    ? emptyInputField
                    : inputField
                  : inputField
              }`}
              maxLength={field.name === "phone" ? 12 : 100}
              onFocus={() => handleFocus(field?.name)}
              onBlur={(e) => handleBlur(e, field?.required)}
              onChange={updateCustomerInfo}
              onInput={(e) => handleBlur(e, field?.required)}
              style={{
                color: "black",
                backgroundColor: "white",
                outlineColor: appointment?.shopData.color,
                boxShadow:
                  appointment?.shopData.shadow === "shadow-md"
                    ? `0 4px 6px ${appointment?.shopData.color}33`
                    : "none",
                border: inputClicked[field.name]
                  ? valueCheck?.includes(field.name)
                    ? `1px solid red`
                    : `1px solid ${appointment?.shopData.color}`
                  : `1px solid ${appointment?.shopData.color}`,
              }}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default CustomerFormFields;
