exports.fetchFullSiteData = async function (domain, shopId) {
  let queryString = "";
  if (domain !== "") {
    queryString = `?domain=${domain}`;
  }
  if (shopId !== "") {
    queryString = queryString + `&shopId=${shopId}`;
  }

  const requestOptions = {
    method: "GET",
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/bookings/config${queryString}`,
      requestOptions
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error fetching site config", error);
  }
};
