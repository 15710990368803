// src/Map.js
import React from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

const Map = ({ markers, handleApiLoad, mapRef, selectedMarker }) => {
  return (
    <>
      <LoadScript
        googleMapsApiKey="AIzaSyAgZpJ8ZNuLhS9bhnaH4TVmaHxX9bb63Hc"
        onLoad={handleApiLoad}
      >
        <GoogleMap
          mapContainerStyle={{
            height: "100%",
            width: "100%",
            borderRadius: "10px",
          }}
          center={markers[0]?.position}
          zoom={9}
          onLoad={(map) => (mapRef.current = map)}
        >
          {markers.map((marker) => (
            <Marker
              key={marker.id}
              position={marker.position}
              title={marker.title.address}
            />
          ))}

          {selectedMarker && (
            <InfoWindow position={selectedMarker.position}>
              <div className="text-sm">
                <strong>{selectedMarker.title?.shopName}</strong>
                <br />
                {selectedMarker.title?.phoneNumber}
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
    </>
  );
};

export default Map;
