import React from "react";
import NextButton from "./NextButton";
import BackButton from "./BackButton";

const NextBackButtons = ({ appointment, clear, confirm = "" }) => {
  return (
    <div className="grid grid-cols-2 gap-4 mx-6">
      <BackButton appointment={appointment} />
      <NextButton appointment={appointment} clear={clear} confirm={confirm} />
    </div>
  );
};

export default NextBackButtons;
