import React, { useState } from "react";
import Datalist from "./Datalist";

import { inputField } from "./css/popupVariables";

function AutoCompleteInput({ options, id, setValue, appointment }) {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    const { value } = event.target;
    if (id === "Year") {
      // Ensure the value is numeric and no more than 4 characters long
      if (value.length <= 4 && /^[0-9]*$/.test(value)) {
        setInputValue(value);
        setValue(event.target.value);
      }
    } else {
      // Ensure the value is no more than 100 characters long
      if (value.length <= 100) {
        setInputValue(value);
        setValue(event.target.value);
      }
    }
  };

  return (
    <div>
      <input
        type={id === "Year" ? "number" : "text"}
        className={`${inputField}`}
        value={inputValue}
        list={id}
        placeholder={id}
        onChange={handleInputChange}
        onSelect={handleInputChange}
        style={{
          backgroundColor: "white",
          boxShadow:
            appointment?.shopData?.shadow === "shadow-md"
              ? `0 4px 6px ${appointment?.shopData?.color}33`
              : "none",
          border: `1px solid ${appointment?.shopData?.color}`,
          fontColor: "black",
          outlineColor: appointment?.shopData?.color,
        }}
      />
      <Datalist data={options} id={id} />
    </div>
  );
}

export default AutoCompleteInput;
