import React from "react";
import { useSelector } from "react-redux";
import { selectAppointments } from "./selectors";

const ShopAddress = ({ appointment }) => {
  // const appointment = useSelector(selectAppointments);
  const shopConfig = appointment?.shopData;

  return (
    <div
      className="text-center py-4 md:pb-0 md:text-right md:pr-4"
      style={{ fontSize: "9pt" }}
    >
      {shopConfig?.shopName?.name}
      <br />
      {shopConfig.shopContact?.address?.street}
      <br />
      {shopConfig.shopContact?.address?.city},{" "}
      {shopConfig.shopContact?.address?.state}{" "}
      {shopConfig.shopContact?.address?.zip}
    </div>
  );
};

export default ShopAddress;
