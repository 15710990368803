exports.parseQueryString = (location) => {
  const searchParams = new URLSearchParams(location.search);
  const params = {};
  for (let [key, value] of searchParams.entries()) {
    params[key] = value;
  }
  return params;
};

exports.validateNumber = function (phoneNumber) {
  // Validate Number length and normalize to +e164
  var phoneNumberPattern = /^\(?\+?1?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  if (phoneNumberPattern.test(phoneNumber)) {
    if (phoneNumber[0] == 1) {
      phoneNumber = "+" + phoneNumber.replace(/\D/g, "");
    } else if (phoneNumber[0] == "+") {
      phoneNumber = "+" + phoneNumber.replace(/\D/g, "");
    } else {
      phoneNumber = "+1" + phoneNumber.replace(/\D/g, "");
    }
    return phoneNumber;
  } else {
    return false;
  }
};

exports.formatPhoneNumber = function (phoneNumber) {
  // Format phone number to (xxx) xxx-xxxx
  var phoneNumberPattern = /^\+?1?(\d{3})(\d{3})(\d{4})$/;
  if (phoneNumberPattern.test(phoneNumber)) {
    var formattedPhoneNumber = phoneNumber.replace(
      phoneNumberPattern,
      "($1) $2-$3"
    );
    return formattedPhoneNumber;
  } else {
    return false;
  }
};

const getVehicleId = (vehicle) => {
  if (
    vehicle &&
    Array.isArray(vehicle.crossRef) &&
    vehicle.crossRef.length > 0
  ) {
    return vehicle.crossRef[0]?.crossRef;
  }
  return null;
};

exports.captureBooking = async function (customer, appointment) {
  const captureUrl = `${process.env.REACT_APP_API_URL}/bookings/capture`;

  const capturePayload = {
    domain: appointment?.domain,
    siteId: appointment?.shopData?.shopId,
    fname: appointment?.customer?.fname,
    lname: appointment?.customer?.lname,
    number: appointment?.customer?.phone,
    email: appointment?.customer?.email,
    apptType: appointment?.apptType,
    duration: appointment?.service?.hours,
    title: appointment?.service?.name,
  };

  try {
    // First fetch call for capture
    const captureResponse = await fetch(captureUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(capturePayload),
    });

    if (!captureResponse.ok) {
      throw new Error(
        "Network response was not ok " + captureResponse.statusText
      );
    }

    const captureData = await captureResponse.json();
    return captureData;
  } catch (error) {
    console.error("Error posting booking config:", error);
  }
};

exports.postBookingConfig = async function (appointment) {
  const bookingUrl = `${process.env.REACT_APP_API_URL}/bookings/book?domain=${appointment.domain}&shopId=${appointment.shopData?.shopId}`;
  try {
    const bookingPayload = {
      apptType: appointment.apptType,
      fname: appointment.customer?.fname,
      lname: appointment.customer?.lname,
      email: appointment.customer?.email,
      number: appointment.customer?.phone,
      phoneNumber: appointment.customer?.phone,

      address1: appointment.customer?.address1,
      city: appointment.customer?.city,
      state: appointment.customer?.state,
      zip: appointment.customer?.zip,

      date: appointment.date,
      time: appointment.time,

      year: appointment.vehicle?.year,
      make: appointment.vehicle?.make,
      model: appointment.vehicle?.model,
      vehicleId: getVehicleId(appointment.vehicle),

      integrationId: appointment.customerId,
      dcId: appointment.dcId,
      service: appointment.service,
      hours: appointment.serviceHours,
      comments: appointment.comments,
      serviceComments: "",
      optIn: true,
      siteId: appointment.shopData?.shopId,

      newAppointmentId: appointment.bookingId,
      rwg_token: appointment.rwg_token,

      // apptType: appointment.slot?.dropOff,
      // fname: appointment.customer?.fname,
      // lname: appointment.customer?.lname,
      // address1: appointment.customer?.address1,
      // city: appointment.customer?.city,
      // state: appointment.customer?.state,
      // zip: appointment.customer?.zip,
      // email: appointment.customer?.email,
      // number: appointment.customer?.phone,
      // date: appointment.slot?.date,
      // time: appointment.slot?.time,
      // year: appointment.vehicle?.year,
      // make: appointment.vehicle?.make,
      // model: appointment.vehicle?.model,
      // vehicleId: getVehicleId(appointment.vehicle),
      // integrationId: appointment.customerId,
      // dcId: appointment.dcId,
      // service: appointment.service.name,
      // hours: appointment.service.hours,
      // comments: appointment.comments,
      // serviceComments: "",
      // optIn: true,
      // siteId: appointment.siteId,
      // phoneNumber: appointment.customer?.phone,
      // newAppointmentId: appointment.newAppointmentId,
      // rwg_token: appointment.rwg_token,
    };
    const bookingResponse = await fetch(bookingUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bookingPayload),
    });

    if (!bookingResponse.ok) {
      throw new Error(
        "Network response was not ok " + bookingResponse.statusText
      );
    }

    await bookingResponse.json();
  } catch (error) {
    console.error("Error posting booking config:", error);
  }
};

exports.customerLookup = async function (customer, config) {
  const url = `${process.env.REACT_APP_API_URL}/bookings/customerLookup?domain=${config.domain}&shopId=${config.shopId}&number=${customer}`;

  try {
    const response = await fetch(url);
    return response.json();
  } catch (error) {
    console.error("Error fetching customer data", error);
  }
};

exports.getBookingTimes = async function (config, date, apptType, appointment) {
  const url = `${process.env.REACT_APP_API_URL}/bookings/apptOptions?domain=${config.domain}&shopId=${config.shopId}&date=${date}&apptType=${apptType}&hours=${appointment.service?.hours}`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    } else {
      return response.json();
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

//  ????????
// fetchFullSiteInfo = async (props) => {
//   let domain = configParams?.domain;
//   let shopId = configParams?.shopId || props?.shopId;

//   let queryString = "";
//   if (domain !== "") {
//     queryString = `?domain=${domain}`;
//   }
//   if (shopId !== "") {
//     queryString = queryString + `&shopId=${shopId}`;
//   }

//   if (domain && shopId) {
//     setBookingsPage(true);
//   }

//   const requestOptions = {
//     method: "GET",
//   };

//   try {
//     const response = await fetch(
//       `${process.env.REACT_APP_API_URL}/bookings/config${queryString}`,
//       requestOptions
//     );

//     const data = await response.json();

//     setCurrentStep(currentStep + 1);
//     setConfig({ ...data, domain: domain });
//     setIsOpen(true);
//   } catch (error) {
//     console.error("Error fetching site config", error);
//   }
// };

// const fetchDomainOnlyData = async () => {
//   let domain = configParams?.domain;
//   let shopId = configParams?.shopId;

//   if (domain && shopId) {
//     setBookingsPage(true);
//   }

//   const requestOptions = {
//     method: "GET",
//   };

//   try {
//     const response = await fetch(
//       `${process.env.REACT_APP_API_URL}/bookings/locations?domain=${domain}`,
//       requestOptions
//     );

//     const data = await response.json();

//     return data
//   } catch (error) {
//     console.error("Error fetching site config", error);
//   }
// };

// exports.fetchSiteConfig = async (domain, shopId) => {
//   if (domain !== "" && shopId !== "") {
//     await fetchFullSiteInfo();
//   } else if (domain !== "") {
//     await fetchDomainOnlyData();
//   } else {
//     console.log("Error fetching site config");
//   }
// };

exports.scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

// exports.handleFocus = (fieldName) => {
//   if (fieldName === "phone" && customer.phone?.length >= 10) {
//     setClear(true);
//   } else {
//     setInputClicked({ ...inputClicked, [fieldName]: true });
//     setClear(false);
//   }
// };

// exports.updateCustomerInfo = (e) => {
//   let fieldName = e.target.name;
//   let fieldValue = e.target.value;
//   let phoneNumber = "";

//   if (fieldName === "phone" && fieldValue.length >= 10) {
//     phoneNumber = validateNumber(fieldValue);
//     if (!phoneNumber) {
//       setErrors({ ...errors, phone: "Invalid phone number" });
//     } else {
//       delete errors.phone;
//       setClear(true);
//     }
//   } else {
//     switch (fieldName) {
//       case "fname":
//         if (fieldValue === "") {
//           setErrors({ ...errors, fname: "First Name is required" });
//         } else {
//           delete errors.fname;
//         }
//         break;

//       case "lname":
//         if (fieldValue === "") {
//           setErrors({ ...errors, lname: "Last Name is required" });
//         } else {
//           delete errors.lname;
//         }
//         break;

//       case "email":
//         if (fieldValue === "") {
//           setErrors({ ...errors, email: "Email Address is required" });
//         } else {
//           delete errors.email;
//         }
//         break;

//       default:
//         break;
//     }
//   }

//   setCustomer((prevCustomer) => ({
//     ...prevCustomer,
//     [fieldName]: fieldValue,
//   }));
// };

// exports.onSelectVehicle = (vehicle) => {
//   onNextStep({
//     vehicle,
//     customerId: customerData?.integrationId,
//     dcId: customerData?._id,
//     customer: {
//       fname: customerData?.fname,
//       lname: customerData?.lname,
//       email: customerData?.email,
//     },
//   });
// };

// exports.onFetchCustomer = async () => {
//   setLoading(true);
//   const custResponse = await customerLookup(customer?.phone, config);

//   if (custResponse.existingCustomer !== false) {
//     setCustomerData(custResponse);
//   }

//   await captureBooking(
//     appointmentParams.customer,
//     config?.shopId,
//     config?.domain
//   ).then((res) => {
//     const queryString = parseQueryString(window.location);
//     const rwg_token = queryString?.rwg_token;

//     setAppointmentParams({
//       ...appointmentParams,
//       newAppointmentId: res?.insertedId,
//       rwg_token: rwg_token,
//     });
//   });

//   setLoading(false);
//   setClear(false);
// };

// exports.enableNext = (values) => {
//   if (values.length === 0) {
//     setDisableNext(false);
//   }
// };

// exports.onNextStep = (params) => {
//   setAppointmentParams({
//     ...appointmentParams,
//     ...params,
//     customer: {
//       ...appointmentParams?.customer,
//       ...params?.customer,
//     },
//     vehicle: { ...appointmentParams?.vehicle, ...params?.vehicle },
//   });

//   setCurrentStep(currentStep + 1);
//   setClear(false);
// };

// exports.onPrevStep = () => {
//   if (currentStep - 1 === 0) {
//     onStartOver();
//   } else {
//     setClear(true);
//     setCurrentStep(currentStep - 1);
//   }
// };

// exports.onConfirmAppointment = (params) => {
//   setAppointmentParams({
//     ...appointmentParams,
//     ...params,
//   });
//   setConfirmed(true);
//   // setIsOpen(false);
//   // setShowConfetti(true);
// };

// exports.handleLeavePage = (event) => {
//   setShowConfirmation(true);
// };

// exports.confirmLeavePage = () => {
//   onStartOver();
// };

// exports.onStartOver = () => {
//   setCurrentStep(0);
//   setConfig(null);
//   setIsOpen(false);
//   setBookingsPage(false);
//   setAppointmentParams({});
//   setCustomer({});
//   setCustomerData({});
//   setDisableNext(true);
//   setErrors({});
//   setInputClicked({});
//   setLoading(true);
//   setServiceSelected({});
//   setShowConfirmation(false);
//   setValueCheck(["cheater"]);
//   setClear(false);
//   setConfirmed(false);
//   window.dcBookings.hide();

//   let tempParams = "";
//   if (window?.location?.queryString !== "") {
//     tempParams = fetchSiteConfig(tempParams);
//   }
// };
