exports.fetchDomainOnlyData = async function (domain) {
  const requestOptions = {
    method: "GET",
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/bookings/locations?domain=${domain}`,
      requestOptions
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error fetching site config", error);
  }
};
