exports.getBookingTimes = async function (date, appointment) {
  const domain = appointment?.domain;
  const shopId = appointment?.shopData?.shopId;
  const apptType = appointment?.apptType;
  const hours = appointment?.serviceHours;
  const service = appointment?.service;

  let queryString = "";
  queryString = queryString + `domain=${domain}`;
  queryString = queryString + `&shopId=${shopId}`;
  queryString = queryString + `&date=${date}`;
  queryString = queryString + `&apptType=${apptType}`;
  queryString = queryString + `&hours=${hours}`;
  queryString = queryString + `&service=${encodeURIComponent(service)}`;

  const url = encodeURI(
    `${process.env.REACT_APP_API_URL}/bookings/apptOptions?${queryString}`
  );

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    } else {
      return response.json();
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
