import { createSlice } from "@reduxjs/toolkit";

const appointmentSlice = createSlice({
  name: "appointments",
  initialState: {},
  reducers: {
    addAppointment(state, action) {
      state.stage = action.payload.stage;
      state.domain = action.payload.domain;
      state.shopData = action.payload.shopData;
      state.rwg_token = action.payload.rwg_token;
    },
    updateAppointment(state, action) {
      return {
        ...state,
        ...action.payload.updates,
      };
    },
    resetAppointment(state, action) {
      return {};
    },
  },
});

export const { addAppointment, updateAppointment, resetAppointment } =
  appointmentSlice.actions;
export default appointmentSlice.reducer;
