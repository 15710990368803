import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import CustomerFormFields from "./CustomerFormFields";
import NextBackButtons from "./NextBackButtons";

import { updateAppointment } from "./appointments/appointmentSlice";
import { parseQueryString, captureBooking, validateNumber } from "./utils";

import { inputField, emptyInputField } from "./css/popupVariables";

const CustomerInput = ({ appointment }) => {
  const [valueCheck, setValueCheck] = useState(["cheater"]);
  const [inputClicked, setInputClicked] = useState({});
  const [errors, setErrors] = useState({});
  const [clear, setClear] = useState(false);
  const [customer, setCustomer] = useState({});

  const dispatch = useDispatch();

  const formFields = [
    {
      type: "text",
      name: "phone",
      required: true,
      placeholder: "Phone Number",
      defaultClass: "inputField",
      emptyClass: "emptyInputField",
    },
  ];

  useEffect(() => {
    let updatedValues = [];
    formFields.map((field) => {
      if (field.required && !customer[field.name]) {
        updatedValues.push(field.name);
      }
    });

    setValueCheck(updatedValues);
  }, []);

  useEffect(() => {
    enableNext(valueCheck);
  }, [valueCheck]);

  const updateCustomerInfo = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let phoneNumber = "";

    if (fieldName === "phone" && fieldValue.length >= 10) {
      phoneNumber = validateNumber(fieldValue);
      if (!phoneNumber) {
        setErrors({ ...errors, phone: "Invalid phone number" });
      } else {
        delete errors.phone;

        dispatch(
          updateAppointment({
            updates: {
              customer: {
                ...appointment?.customer,
                [fieldName]: fieldValue,
              },
            },
          })
        );

        let tempCustomer = { phone: fieldValue };
        captureBooking(tempCustomer, appointment).then((res) => {
          dispatch(
            updateAppointment({
              updates: { bookingId: res.insertedId },
            })
          );
        });
        setClear(true);
      }
    } else {
      switch (fieldName) {
        case "fname":
          if (fieldValue === "") {
            setErrors({
              ...errors,
              fname: "First Name is required",
            });
          } else {
            delete errors.fname;
            dispatch(
              updateAppointment({
                updates: {
                  customer: { [fieldName]: fieldValue },
                },
              })
            );
          }
          break;

        case "lname":
          if (fieldValue === "") {
            setErrors({
              ...errors,
              lname: "Last Name is required",
            });
          } else {
            delete errors.lname;
            dispatch(
              updateAppointment({
                updates: {
                  customer: { [fieldName]: fieldValue },
                },
              })
            );
          }
          break;

        case "email":
          if (fieldValue === "") {
            setErrors({
              ...errors,
              email: "Email Address is required",
            });
          } else {
            delete errors.email;
            dispatch(
              updateAppointment({
                updates: {
                  customer: { [fieldName]: fieldValue },
                },
              })
            );
          }
          break;

        default:
          break;
      }
    }
  };

  const handleFocus = (fieldName) => {
    setInputClicked({ ...inputClicked, [fieldName]: true });
  };

  const handleBlur = (e, isRequired) => {
    let updatedValues = [];

    if (e.target.value !== "") {
      updatedValues = valueCheck?.filter((item) => item !== e.target.name);
      setValueCheck(updatedValues);
    } else if (!valueCheck?.includes(e.target.name) && isRequired) {
      setValueCheck((oldValues) => [...oldValues, e.target.name]);
    }
  };

  const enableNext = (values) => {
    if (values.length === 0) {
      setClear(true);
    }
  };

  return (
    <>
      <div
        className="pt-5 mb-5 w-full grid justify-center text-center"
        style={{ color: "black" }}
      >
        Enter your phone number to look up your account or create one
      </div>

      {Object.keys(errors).length > 0 && (
        <div className="mx-6 mb-5 p-1 bg-red-200 border-solid border-[1px] border-red-500 rounded-md text-red-500">
          <ul>
            {Object.keys(errors).map((error, index) => (
              <li key={index}>{errors[error]}</li>
            ))}
          </ul>
        </div>
      )}

      <CustomerFormFields
        props={{
          formFields: formFields,
          appointment: appointment,
          customer: customer,
          inputClicked: inputClicked,
          valueCheck: valueCheck,
          emptyInputField: emptyInputField,
          inputField: inputField,
          updateCustomerInfo: updateCustomerInfo,
          handleFocus: handleFocus,
          handleBlur: handleBlur,
        }}
      />

      <br />

      <NextBackButtons appointment={appointment} clear={clear} />

      <center>
        <div className="cols-span-2 mt-5 text-xs text-gray-500 mx-6">
          By submitting this form, you agree to receive recurring automated
          promotional and personalized marketing and informational text messages
          (e.g. appointment reminders, status updates, etc.) from us at the cell
          number provided. Consent is not a condition of any purchase. Reply
          HELP for help and STOP to cancel. Msg frequency varies. Msg and data
          rates may apply.
        </div>
      </center>
    </>
  );
};

export default CustomerInput;
